<template>
    <b-row>
        
        <b-col class="text-center">
            
            <b-link :to="{ name: 'page-policy' }" >Политика конфиденциальности</b-link>
            <p><b-link :to="{ name: 'page-public-offer' }">Публичная оферта</b-link></p>
            
            <p class="mb-0">ИП Нуртдинов Тимур Булатович</p>
            <p class="mb-0">© Qubity {{ new Date().getFullYear() }}</p>
            <p>Все права защищены.</p>
            
        </b-col>
        
<!--        <b-col lg="6" offset-lg="3">
            <b-card bg-variant="transparent" class="shadow-none">
                <p class="clearfix mb-0">
                    <span class="mt-25">
                      <p class="text-center">ИП Нуртдинов Тимур Булатович</p>
                      <b-row>
                          <b-col cols="12" class="text-right">
                              <b-link >Политика конфиденциальности</b-link>
                          </b-col>
                          <b-col cols="12">
                              <b-link>Публичная оферта</b-link>
                          </b-col>
                      </b-row>
                      COPYRIGHT  © Qubity {{ new Date().getFullYear() }}
                      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
                    </span>    
                </p>
            </b-card>
        </b-col>-->
        
    </b-row>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
